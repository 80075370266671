import { CssBaseline, ThemeProvider } from "@mui/material";
import { Analytics as VercelAnalytics } from "@vercel/analytics/react";
import axios from "axios";
import Head from "next/head";
import Script from "next/script";
import React, { useEffect } from "react";

import { SpeedInsights } from "@vercel/speed-insights/next";
import { theme } from "../src/constants/theme";
import { WhatsAppPopupProvider } from "../src/context/context";
import { NavbarActiveItemProvider } from "../src/context/navbarContext";
import { MemoizedStatePopup } from "../src/Popups/statePopup/StatePopup";
import { MemoizedSnackBar } from "../src/SDK/snackbar";
import { locationTracking } from "../src/services/locationService";
// import "../src/styles/neededCss.css";
import '../styles/base/global.scss'
import { CapturePageHit } from "../src/utils/capturePageHit";
import { appWithTranslation } from 'next-i18next'
import { useRouter } from "next/router";

axios.defaults.baseURL = process.env.NEXT_PUBLIC_API_ENDPOINT;
axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

const App = ({ Component, pageProps }) => {
  const router = useRouter();
  useEffect(() => {
    const head = document.querySelector("head");
    const script = document.createElement("script");

    script.src = process.env.NEXT_PUBLIC_CASHFREE_SCRIPT_LINK;
    head.appendChild(script);

    return () => {
      head.removeChild(script);
    };
  }, []);

  const setLocaleBasedOnLocation = async () => {
    const pathname = window.location.pathname;
      if (pathname.includes("/hi/")) {
        localStorage.setItem("userLocale", "hi");
        return;
      }

      if (pathname.includes("/en/")) {
        localStorage.setItem("userLocale", "en");
        return;
      }
    const storedLocale = localStorage.getItem("userLocale");
    if (!storedLocale) {
      await locationTracking();
      const geoLocation = JSON.parse(localStorage.getItem("geoLocation"));
      if (geoLocation) {
        const { countryRegion } = geoLocation;
        const hindiRegions = ["UP"]; 
        const newLocale = hindiRegions.includes(countryRegion) ? "hi" : "en";
        if (router.locale !== newLocale) {
          router.push(router.asPath, router.asPath, { locale: newLocale });
        }
        localStorage.setItem("userLocale", newLocale);
      }
    }

    if (storedLocale) {
      router.push(router.asPath, router.asPath, { locale: storedLocale });
    }

  }

  useEffect(() => {
    CapturePageHit();
    setLocaleBasedOnLocation();
    locationTracking();
  
  }, []);

  return (
    <>
      {/* <Script async src={process.env.NEXT_PUBLIC_CASHFREE_SCRIPT_LINK}></Script> */}
      <Head>
        <>
          <Script
            strategy="afterInteractive"
            src="https://www.googletagmanager.com/gtag/js?id=G-NF49NHPB76"
            defer
          ></Script>

          <Script id="gtag" strategy="afterInteractive" defer>
            {` window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-NF49NHPB76');`}
          </Script>

          {/* Google tag (gtag.js) */}
          {/* <Script
            strategy="afterInteractive"
            src="https://www.googletagmanager.com/gtag/js?id=UA-224876127-1"
          ></Script> */}

          {/* <Script id="gtag" strategy="afterInteractive">
            {`
             window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag("js", new Date());

      gtag("config", "UA-224876127-1");
            `}
          </Script> */}

          <Script
            strategy="afterInteractive"
            src={process.env.NEXT_PUBLIC_CASHFREE_SCRIPT_LINK}
            defer
          ></Script>
        </>
      </Head>
      <WhatsAppPopupProvider>
        <ThemeProvider theme={theme}>
          {/* <ContextProviderWrapper> */}
          <NavbarActiveItemProvider>
            <CssBaseline>
              {/* <MemoizedWhatsAppDrawer /> */}
              {/* <MemoizedWhatsAppPopup /> */}
              {/* <MemoizedWhatsAppPopup />
              <MemoizedSnackBar />
                <MemoizedWhatsAppDrawer />
                {mobileView ? (
                  <MemoizedWhatsAppDrawer />
                ) : (
                  <MemoizedWhatsAppPopup />
                )} */}
              <MemoizedStatePopup />
              {/* <MemoizedCityPopup /> */}
              <MemoizedSnackBar />

              <Component {...pageProps} />
              <SpeedInsights />
            </CssBaseline>
          </NavbarActiveItemProvider>
          {/* </ContextProviderWrapper> */}
        </ThemeProvider>
      </WhatsAppPopupProvider>

      <VercelAnalytics />
    </>
  );
};

export default appWithTranslation(App);
