export const findPageId = () => {
  if (window.location.pathname === "/") {
    return "1";
  } else if (window.location.pathname === "/vehicles") {
    return "2";
  } else if (
    window.location.pathname === "/vehicles/piaggio" ||
    window.location.pathname === "/vehicles/OSM" ||
    window.location.pathname === "/vehicles/mahindra" ||
    window.location.pathname === "/vehicles/ETRIO"
  ) {
    return "3";
  } else if (window.location.pathname === "/exchange") {
    return "4";
  } else if (window.location.pathname === "/referral") {
    return "5";
  } else if (window.location.pathname.includes("/payments")) {
    return "6";
  } else if (window.location.pathname === "/faqs") {
    return "7";
  } else if (window.location.pathname === "/enquiry") {
    return "8";
  } else if (window.location.pathname === "/tco") {
    return "9";
  } else {
    return "1";
  }
};
