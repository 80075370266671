export const findBrowser = () => {
  if (typeof window !== "undefined") {
    let isChromium = window.chrome;
    let winNav = window.navigator;
    let vendorName = winNav.vendor;
    let isOpera = typeof window.opr !== "undefined";
    let isIEedge = winNav.userAgent.indexOf("Edg") > -1;
    let isIOSChrome = winNav.userAgent.match("CriOS");

    var isSafari = window.safari !== undefined;
    if (isSafari) console.log("Safari, yeah!");

    if (isIOSChrome) {
      return "Chrome";
    } else if (
      isChromium !== null &&
      typeof isChromium !== "undefined" &&
      vendorName === "Google Inc." &&
      isOpera === false &&
      isIEedge === false
    ) {
      return "Chrome";
    } else {
      return "Safari";
    }
  } else return "Chrome";
};
