import React from "react";

const navbarActions = {
  ACTIVE_ITEM_CHANGED: "activeItemChanges",
};

const initialState = {
  value: 0,
};

const reducer = (state, action) => {
  switch (action.type) {
    case navbarActions.ACTIVE_ITEM_CHANGED: {
      const newstate = {
        value: action?.obj?.value,
      };
      return newstate;
    }

    default:
      return state;
  }
};

export const ActiveItemValue = React.createContext();

export const NavbarActiveItemProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const value = {
    value: state?.value,
    activeItemChanges: (value) => {
      const obj = {
        value,
      };
      dispatch({ type: navbarActions.ACTIVE_ITEM_CHANGED, obj });
    },
  };

  return (
    <ActiveItemValue.Provider value={value}>
      {children}
    </ActiveItemValue.Provider>
  );
};
