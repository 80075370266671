import axios from "axios";

import { getCookie } from "../utils/cookie";

export const headers = {
  "Content-Type": "application/json",
};

const sessionTracking = async (payload) => {
  headers.auth_token = process.env.NEXT_PUBLIC_TOKEN;

  localStorage.setItem("uuid", `${payload?.leadUuid}`);
  let cacheInfo = getCookie("user_verified")
    ? JSON.parse(getCookie("user_verified"))
    : "";

  const payloadInfo = {
    leadUuid: payload?.leadUuid,
    newSession: parseInt(localStorage.getItem("initialSessionCount")),
    formSubmitted: payload?.formSubmitted,
    phoneNumber: `+91${cacheInfo?.phone}`,
  };

  if (payload?.formSubmitted) {
    payloadInfo.leadIntent = payload?.leadIntent;
  }

  localStorage.getItem("phoneInfo");

  try {
    if (typeof window !== "undefined" && window?.Moengage) {
      Moengage?.add_mobile(
        `${
          localStorage.getItem("phoneInfo")
            ? `+91${localStorage.getItem("phoneInfo")}`
            : ""
        }`
      );

      Moengage?.update_unique_user_id(payload?.leadUuid);
    } else {
      setTimeout(() => {
        Moengage?.add_mobile(
          `${
            localStorage.getItem("phoneInfo")
              ? `+91${localStorage.getItem("phoneInfo")}`
              : ""
          }`
        );

        Moengage?.update_unique_user_id(payload?.leadUuid);
      }, 3000);
    }
  } catch (error) {
    console.log(error);
  }

  await axios
    .post("/v1/analytics/leads", payloadInfo)
    .then((res) => {
      localStorage.removeItem("initialSessionCount");
    })
    .catch((error) => {
      console.log("error", error);
    });
};

export default sessionTracking;
