import { Box, styled, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import { useRouter } from "next/router";
import React, { useContext } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { imagesList, stateArray } from "../../constants";
import { Context } from "../../context/context";
import { sendAnalytics } from "../../utils/analyticsCommon";

const StatePopupDialog = styled(Box)(({ theme }) => ({
  ".state-images": {
    width: "100%",
  },
  ".text": {
    marginBottom: "10px",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "32px",
    lineSeight: "130%",
    textAlign: "center",
    color: "#5d5bc0",
  },

  [theme.breakpoints.down("md")]: {
    ".text": {
      fontSize: "20px",
    },
  },
}));

const StatePopup = () => {
  const state = useContext(Context);
  let open = state.showStatePopupValue;
  let vehicle_brand = state.vehicleBrand;
  const { hideStatePopup, changeState } = useContext(Context);
  const navigate = useRouter();

  const stateHandle = (stateInfo) => {
    const routeName = navigate.query;
    changeState(stateInfo);
    const pathname = routeName.location;

    if (state?.selectedStateRoute === "false") {
    } else if (pathname === undefined) {
      navigate.push(`${navigate.pathname}/${vehicle_brand}`);
    } else {
      navigate.push(`/${pathname}/${vehicle_brand}`);
    }

    hideStatePopup();
    const payload = {
      state_selected: state,
    };
    sendAnalytics("state_selected", payload, "en");
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        hideStatePopup();
        sendAnalytics("state_popup_dismissed", {}, "en");
      }}
    >
      <DialogContent>
        <StatePopupDialog>
          <Box>
            <Typography className="text">{"Select State"}</Typography>
            <Grid
              container
              rowSpacing={2}
              columnSpacing={{ xs: 3, sm: 1, md: 2 }}
            >
              {stateArray?.map((state) => {
                return (
                  <Grid key={state?.name} item xs={6} sm={4}>
                    <LazyLoadImage
                      className="state-images"
                      alt={"vehicles"}
                      effect="opacity"
                      src={imagesList[state?.imageName]}
                      onClick={() => stateHandle(state?.name)}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </StatePopupDialog>
      </DialogContent>
    </Dialog>
  );
};
// export default StatePopup;
export const MemoizedStatePopup = React.memo(StatePopup);
