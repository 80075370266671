import amplitudePlugin from "@analytics/amplitude";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import Analytics from "analytics";
import sessionTracking from "../services/sessionTracking";

import googleAnalytics from "@analytics/google-analytics";
import googleTagManager from "@analytics/google-tag-manager";

import { createCookieInHour } from "./cookie";
import { findBrowser } from "./findBrowserUtil";

export const analytics = Analytics({
  app: "awesome-app",
  plugins: [
    amplitudePlugin({
      apiKey: process.env.NEXT_PUBLIC_AMPLITUDE_KEY,
      initialSessionId:
        typeof localStorage !== "undefined"
          ? JSON.parse(localStorage.getItem("user_id"))
          : ""
            ? JSON.parse(localStorage.getItem("user_id"))
            : "",
      options: {
        trackingOptions: {
          ip_address: true,
        },

        includeUtm: true,
        includeFbclid: true,
        includeGclid: true,
        includeReferrer: true,
        logAttributionCapturedEvent: true,
      },
    }),

    googleAnalytics({
      measurementIds: ["G-NF49NHPB76"],
      initialSessionId:
        typeof localStorage !== "undefined"
          ? JSON.parse(localStorage.getItem("user_id"))
          : ""
            ? JSON.parse(localStorage.getItem("user_id"))
            : "",
      options: {
        trackingOptions: {
          ip_address: true,
        },

        includeUtm: true,
        includeFbclid: true,
        includeGclid: true,
        includeReferrer: true,
        logAttributionCapturedEvent: true,
      },
    }),

    googleTagManager({
      containerId: "GTM-N4HDJW6",
      initialSessionId:
        typeof localStorage !== "undefined"
          ? JSON.parse(localStorage.getItem("user_id"))
          : ""
            ? JSON.parse(localStorage.getItem("user_id"))
            : "",
      options: {
        trackingOptions: {
          ip_address: true,
        },

        includeUtm: true,
        includeFbclid: true,
        includeGclid: true,
        includeReferrer: true,
        logAttributionCapturedEvent: true,
      },
    }),
  ],
});

const parameters = {};

const changeState = (param, value) => {
  if (param?.has(value) && param?.get(value)?.length > 0) {
    parameters[value] = param?.get(value);
  } else {
    if (value === "utm_campaign") parameters[value] = "999";
    else if (value === "utm_term") parameters[value] = "Unknown";
    else parameters[value] = "Organic";
  }
};

const getSentryInit = async () => {
  Sentry.init({
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    ignoreErrors: [
      "getReadModeConfig",
      "getReadModeExtract",
      "getReadModeRender",
    ],
  });
};

export const CapturePageHit = () => {
  getSentryInit();

  const broswer = findBrowser();
  localStorage.setItem("browser", broswer);

  const queryString = window?.location?.search;

  if (queryString !== null) {
    const urlParams = new URLSearchParams(queryString);

    changeState(urlParams, "utm_source");
    changeState(urlParams, "utm_medium");
    changeState(urlParams, "utm_campaign");
    changeState(urlParams, "utm_content");
    changeState(urlParams, "utm_term");
    parameters["entire_url"] = window?.location?.href;

    const appInfo = {
      appToken: urlParams?.get("token") ?? "",
      appSource: urlParams?.get("token_src") ?? "",
    };

    if (appInfo?.appSource) {
      parameters["utm_source"] = appInfo?.appSource;
    }

    // if (appInfo?.appSource && appInfo?.appSource?.length > 0) {
    //   appUserInfo(appInfo?.appToken);
    // }

    if (!localStorage.getItem("appInfo")) {
      localStorage.setItem("appInfo", JSON.stringify(appInfo));
    } else {
      localStorage.setItem("appInfo", JSON.stringify(appInfo));
    }

    if (typeof localStorage !== "undefined") {
      if (!localStorage.getItem("urlParams")) {
        localStorage.setItem("urlParams", JSON.stringify(parameters));
      } else {
        if (
          queryString?.length > 0 &&
          JSON.parse(localStorage.getItem("urlParams"))?.utm_campaign !==
            urlParams?.get("utm_campaign")
        ) {
          localStorage.setItem("urlParams", JSON.stringify(parameters));
        }
      }
    }
  }

  var ID, sId;

  if (!JSON.parse(localStorage.getItem("user_id"))) {
    ID = "_" + Math.random()?.toString(36).substr(2, 9);
    localStorage.setItem("user_id", JSON.stringify(ID));
  } else {
    ID = JSON.parse(localStorage.getItem("user_id"));
  }

  createCookieInHour("user_Id", ID, 10);

  if (!JSON.parse(sessionStorage.getItem("sessionId"))) {
    sId = "_" + Math.random()?.toString(36).substr(2, 9);
    sessionStorage.setItem("sessionId", JSON.stringify(sId));

    if (!JSON.parse(localStorage.getItem("initialSessionCount"))) {
      localStorage.setItem("initialSessionCount", "1");
    } else {
      let sessionCount =
        parseInt(localStorage.getItem("initialSessionCount")) + 1;
      localStorage.setItem("initialSessionCount", `${sessionCount}`);
    }

    if (
      localStorage?.getItem("uuid") !== null &&
      localStorage.getItem("uuid").length > 0
    ) {
      const sessionInfo = {
        leadUuid: localStorage.getItem("uuid"),
        newSession: true,
        formSubmitted: false,
      };

      sessionTracking(sessionInfo);
    }
  }

  analytics.identify(ID, {});

  // const windowMoengage = typeof window !== "undefined" && window?.Moengage;

  try {
    if (
      localStorage?.getItem("uuid") !== null &&
      localStorage.getItem("uuid").length > 0
    ) {
      if (typeof window !== "undefined" && window?.Moengage) {
        Moengage?.update_unique_user_id(localStorage?.getItem("uuid"));
      } else {
        setTimeout(() => {
          Moengage?.update_unique_user_id(localStorage?.getItem("uuid"));
        }, 3000);
      }
    } else {
      if (typeof window !== "undefined" && window?.Moengage) {
        Moengage?.add_unique_user_id(ID);
      } else {
        setTimeout(() => {
          Moengage?.add_unique_user_id(ID);
        }, 3000);
      }
    }
  } catch (error) {
    console.log(error);
  }

  let payload = {
    CLICK_WHATSAPP_CHAT: false,
    CLICK_BOOK_VEHICLE: false,
    CLICK_BOOK_TEST_DRIVE: false,
    CLICK_VEHICLE_LOAN: false,
    CLICK_SAVINGS_ESTIMATION: false,
    CLICK_EV_CONSULTATION: false,
  };
  createCookieInHour("lead_actions", payload, 10);

  // eslint-disable-next-line react-hooks/exhaustive-deps
};
