export function getCookie(cname) {
  let name = cname + "=";

  if (typeof window !== `undefined`) {
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca?.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c?.substring(1);
      }
      if (c?.indexOf(name) === 0) {
        return c?.substring(name?.length, c?.length);
      }
    }
  }
  return "";
}

export const createCookieInHour = (cookieName, cookieValue, daysToExpire) => {
  let date = new Date();
  date.setTime(date.getTime() + daysToExpire * 24 * 60 * 60 * 1000);
  // document.cookie = `${cookieName} =   ${JSON.stringify(cookieValue)} ;path=/`;

  document.cookie = `${cookieName} = ${JSON.stringify(cookieValue)}; Domain = ${
    process.env.NEXT_PUBLIC_COOKIE_DOMAIN
  }; expires = ${date.toGMTString()};`;
};

export function deleteCookie(cookieName) {
  document.cookie =
    cookieName + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}
