// import posthog from "posthog-js";

import { analytics } from "./capturePageHit";
import { findPageId } from "./findPageId";

export const sendAnalytics = (event_name, payload, language) => {
  /* Apend_Experiment_Variants over here or add in string with comma separated  */

  if (typeof localStorage !== "undefined") {
    const experimentName = "otpVerificationExp";
    let experimentInfo = "NoOTPFAQ";
    // JSON.parse(localStorage.getItem("abExperimentInfo")) ?? "control";

    let paraLocal = JSON.parse(localStorage.getItem("urlParams"));
    let combined_payload = {
      user_id: JSON.parse(localStorage.getItem("user_id"))
        ? JSON.parse(localStorage.getItem("user_id"))
        : "",
      page_language: language,
      page_id: findPageId(),
      experiment_name: experimentName,
      audience_type: experimentInfo?.["experimentVariant"] ?? "control",
      entireUrl: paraLocal?.entire_url ?? "",
      referrer_Url: document?.referrer ? document?.referrer : "direct",
    };
    combined_payload = { ...combined_payload, ...payload };

    analytics.track(event_name, combined_payload);

    try {
      if (typeof window !== "undefined" && window?.Moengage) {
        Moengage?.track_event(event_name, combined_payload);
      } else {
        setTimeout(() => {
          Moengage?.track_event(event_name, combined_payload);
        }, 3000);
      }
    } catch (error) {
      console.log(error);
    }

    // if (
    //   event_name === "whatsapp_popup_dismissed" ||
    //   event_name === "home_icon_clicked"
    // ) {
    //   analytics.track(event_name, combined_payload);
    // } else {
    //   analytics.track(event_name, combined_payload);

    //   // if (!event_name?.includes("scroll")) {
    //   //   posthog.capture(event_name, {
    //   //     ...combined_payload,
    //   //     ...{ ab_experiment: "vehicle-page" },
    //   //   });
    //   // }
    // }
  } else {
  }
};
