import { KeyboardArrowDownRounded } from "@mui/icons-material";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";

export const primary = {
  main: "#E8345E",
  secondary: "#5D5BC0",
  lightMain: "#FFCCD8F",
  dark: "#333333",

  mainText: "#333333",
  secondaryText: "#5D5BC0",
  inactiveState: "#EDECE8",
  greyText: "#9C9A9D",

  mainGreen: "#35BF68",
  secondaryGreen: "#EBF9F0",
  lightGreen: "#DFFFEB",

  lightYellowBg: "#FEF8E6",
  lightWhiteBg: "#F7F8FA",

  warning: "#FFB636",
  white: "#FFFFFF",

  darkGreen: " #2D804C",
  lightPink: "#FFEDF1",
  lightGrey: "#f0f0f5",

  flagSaffron: "#ff9a2f",
  flagGreen: "#0a8901",
  electricGreen: "#00EE98",

  matPink: "#C091AC",
  black: "#000000",

  gameBlue: "#803BAA",
  gameGreen: "#00B9A5",
  matYellow: "#EFE6D5",
  mediumMatYellow: "#F4D9B0",
  darkMatYellow: "#E0D2BC",
  darkYellow: "#F9EAB8",
  lightYellow: "#FFFCF2",
  skyBlue: "#0469FF",
  lightBlue: "#EBF4FD",
  thinLightPink: "#FFF8F9",
};

const fontFamilyInfo = `'Poppins', sans-serif `;

let themeDef = createTheme({
  transitions: {
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      // most basic recommended timing
      standard: 300,
      // this is to be used in complex animations
      complex: 375,
      // recommended when something is entering screen
      enteringScreen: 225,
      // recommended when something is leaving screen
      leavingScreen: 195,
    },

    easing: {
      // This is the most common easing curve.
      easeInOut: "cubic-bezier(0.4, 0, 0.2, 1)",
      // Objects enter the screen at full velocity from off-screen and
      // slowly decelerate to a resting point.
      easeOut: "cubic-bezier(0.0, 0, 0.2, 1)",
      // Objects leave the screen at full velocity. They do not decelerate when off-screen.
      easeIn: "cubic-bezier(0.4, 0, 1, 1)",
      // The sharp curve is used by objects that may return to the screen at any time.
      sharp: "cubic-bezier(0.4, 0, 0.6, 1)",
    },
  },
  typography: {
    fontFamily: `${fontFamilyInfo} !important`,
    color: primary.mainText,
    allVariants: {},
  },

  palette: {
    primary: primary,
  },

  components: {
    MuiPaper: {
      defaultProps: {
        style: {
          fontFamily: `${fontFamilyInfo} !important`,
        },
      },
    },
    MuiInput: {
      defaultProps: {
        disableUnderline: true,
      },
      styleOverrides: {
        fontFamily: `${fontFamilyInfo} !important`,
        fontSize: { md: "24px", sm: "12px" },
        borderRadius: "16px",
        "&.Mui-focused": {
          backgroundColor: "transparent",
          boxShadow: `
          `,
          borderColor: primary.secondary,
        },
      },
    },
    MuiGrid: {
      defaultProps: {
        display: "flex",
        alignItems: "stretch",
      },
    },
    MuiButton: {
      defaultProps: {
        variant: "contained",
      },
      styleOverrides: {
        root: {
          fontFamily: `${fontFamilyInfo} !important`,
          borderRadius: "82px",
          textTransform: "none",
          color: primary.white,
          background: primary.main,
          fontWeight: "550",
          border: "1px solid #FFFFFF",
          boxShadow: "0px 4px 4px rgba(232, 52, 94, 0.7)",
          "&:hover": {
            color: primary.dark,
          },
        },
        sizeMedium: {
          fontSize: "16px",
          padding: "12px 30px 12px  30px",
        },
      },
    },

    MuiTextField: {
      defaultProps: {
        variant: "outlined",
      },
      styleOverrides: {
        root: {
          fontFamily: `${fontFamilyInfo} !important`,
          minHeight: 48,
          "&.Mui-focused": {
            backgroundColor: "transparent",
            boxShadow: `${(primary.light, 0.25)} 0 0 0 2px`,
            borderColor: primary.secondary,
          },
        },
      },
    },

    MuiSelect: {
      defaultProps: {
        MenuProps: {
          style: {
            maxHeight: 400,
            fontFamily: `${fontFamilyInfo} !important`,
          },
        },
        IconComponent: KeyboardArrowDownRounded,
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          fontFamily: `${fontFamilyInfo} !important`,
        },
      },
    },

    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: primary,
          fontFamily: `${fontFamilyInfo} !important`,
          borderRadius: "16px 16px 0px 0px",
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontFamily: `${fontFamilyInfo} !important`,
          background: "black",
          color: "white",
          margin: 16,
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          fontFamily: `${fontFamilyInfo} !important`,
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          fontFamily: `${fontFamilyInfo} !important`,
        },
      },
    },
  },
});

export const theme = responsiveFontSizes(themeDef);
