import axios from "axios";

export const locationTracking = async () => {
  await axios
    .get(`${process.env.NEXT_PUBLIC_BASE_URL}/api/geo_ip`)
    .then((res) => {
      localStorage.setItem("geoLocation", JSON.stringify(res?.data));
    })
    .catch((error) => {
      console.log("error", error);
    });
};

export const getLocationInfo = () => {
  return axios.get(
    `${process.env.NEXT_PUBLIC_BASE_URL ?? "https://www.turno.club"}/api/geo_ip`,
  );
};
